/* Snow Theme */
.editorCierreAeropuerto .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.editorCierreAeropuerto .ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.editorCierreAeropuerto .ql-editor {
    min-height: 10em;
}

.editorCierreAeropuerto .ql-editor::placeholder {
    font-style: normal;
}

.editorCierreAeropuerto .ql-container.ql-snow {
    min-height: 50px;
}

.form-input .periodos {
    color: #65B331;
    display: inline-flex;
    align-items: center;
}

.form-input .periodos .span {
    font-size: 1.25rem;
    line-height: 1.688rem;
}

.form-input .periodos input {
    margin: 0 16px;
}

.form-input .periodos span.text {
    margin: 0 16px;
}


.form-input .text {
    color: #9D9D9D;
    font-size: 1.125rem;
    line-height: 1.5rem;
    opacity: .94;
    margin: 10px 0 0 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-group .label {
    margin-left: 16px;
}