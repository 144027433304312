.estacionamiento-box {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, .16);
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
}

.estacionamiento-box .top svg {
    float: right;
    cursor: pointer;
    transition: .4s all;
}

.estacionamiento-box .top svg.activo {
    fill: #59C576;
}

.estacionamiento-box .top svg.activo:hover {
    fill: #A5A5A5;
}

.estacionamiento-box .top svg.inactivo {
    fill: #A5A5A5;
}

.estacionamiento-box .estacionamiento-name {
    display: grid;
    align-items: center;
    font-size: 1.125em;
    line-height: 1.5rem;
    color: #860A5D;
    text-align: center;
    margin-bottom: 35px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.estacionamiento-box .estacionamiento-logo {
    display: flex;
    margin: auto;
    text-align: center;
    width: 75px;
    height: 75px;
    background: #E9E9E9;
    border-radius: 50%;
    margin-bottom: 15px;
    position: relative;
    align-items: center;
    justify-content: center;
}

.estacionamiento-box .estacionamiento-logo svg {
    font-size: 2.1875em;
    margin: auto;
}

.estacionamiento-box .estacionamiento-logo svg path {
    fill: #1068A0;
}

.estacionamiento-box .estacionamiento-logo .count {
    display: flex;
    position: absolute;
    right: -15px;
    bottom: -5px;
    border-radius: 50%;
    background: #59C576;
    color: white;
    font-size: .7em;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
}

.estacionamiento-box .middle {
    text-align: center;
    margin-bottom: 15px;
}

.estacionamiento-box .middle span {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #333333;
}

.estacionamiento-box .bottom {
    display: flow-root;
}

.estacionamiento-box .bottom .del {
    fill: #C1BABA;
    cursor: pointer;
}

.estacionamiento-box .bottom .del:hover {
    fill: #4969D4;
}

.estacionamiento-box .bottom .details {
    float: right;
    color: #4969D4;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}