.form-input .tipo-aeropuerto {
    display: inline-block;
    width: 50%;
}

.form-input .tipo-aeropuerto .MuiFormGroup-root .MuiIconButton-colorSecondary:hover {
    background: transparent !important;
}

.form-input .tipo-aeropuerto .MuiFormGroup-root .MuiRadio-colorSecondary.Mui-checked {
    color: #59C576 !important;
}

.form-input .horario-box {
    display: inline-flex;
    align-items: center;
}

.form-input .horario-box .horario input {
    margin: 0 5px !important;
    max-width: 25%;
}

.form-input .telefono {
    width: 100%;
    display: inline-flex;
    margin: 0 20px 0 0;
    flex-direction: row;
    gap: 10px;
}

.form-input .tarifa-label {
    display: inline-block;
}

.form-input .addbtn-tarifa {
    background: #4969D4;
    color: white;
    border-radius: 4px;
    margin-left: 25px;
    cursor: pointer;
}

.form-input table.tarifas th {
    color: #707070;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: normal;
    border: none;
    height: 56px;
    vertical-align: inherit;
    padding: 0 5px;
}

.form-input table.tarifas input {
    margin: 0;
}

.form-input table.tarifas th .actions {
    position: unset !important;
    display: inline-flex;
    align-items: center;
}

.form-input table.tarifas th .actions svg {
    cursor: pointer;
    margin: 0 5px;
}

.form-input table.tarifas th .actions svg.edit {
    fill: #4969D4;
}

.form-input table.tarifas th .actions svg.del {
    fill: #860A5D;
}

.form-input table.tarifas th .actions svg.save {
    fill: #29ab2d
}

.form-input table.tarifas th .actions svg.cancel {
    fill: #c3c3c3;
}

.abmImage {
    width: 140px;
}

.abmImageDel span {
    color: #860A5D;
}