/*************** SEARCH *****************/

.search-box {
    display: flex;
    align-items: center;
}

.search-box:hover svg path,
.search-box:focus-within svg path {
    fill: #797474;
}

.search-box svg {
    font-size: larger;
    vertical-align: middle;
    margin-right: 10px;
}

.search-box svg path {
    fill: #A5A5A5;
}

.search-box input {
    background: none;
    border-radius: 0;
    border-width: 0px 0px 1px 0px;
    border-color: #707070;
    transition: .4s all;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    outline: none;
}

.search-box:hover input,
.search-box:focus-within input {
    border-width: 0px 0px 3px 0px;
}

.position-items {
    position: relative;
    float: left;
    display: block;
    width: auto;
}

.position-items .position-item:nth-child(1) {
    margin-top: 0;
}

.position-items .position-item {
    font-size: 25px;
    line-height: 33px;
    letter-spacing: 0px;
    color: #A5A5A5;
    align-items: center;
    height: 48px;
    margin: 20px 0;
    text-align: center;
    display: grid;
}

.simple-page {
    display: contents;
    width: auto;
    float: right;
}

/************* TITLE **********/
.titleFAQ{ 
    padding: 27px 22px 47px 22px !important;
}

/************* DRAG AND DROP ITEMS **********/

.simple-page-scroller{
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    overflow-y: auto;
    height: 80vh;
  }

.smooth-dnd-draggable-wrapper {
    display: flex !important;
    align-items: center;
    margin: 20px 0;
}

.draggable-item {
    background: white;
    box-shadow: 0px 5px 6px #00000029;
    border-radius: 3px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #6C6C6C;
    padding: 10px;
    margin-left: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.column-drag-handle {
    display: inline-block;
    margin-right: 15px;
    /* float: left; */
    padding: 0px 10px;
    font-size: 20px;
    cursor: move;
}

.draggable-item svg {
    vertical-align: middle;
}

.draggable-item svg path {
    fill: #8E8E8E !important;
}

.item-text {
    display: inline-block;
    max-width: calc(100% - 200px);
    min-width: 90px;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    display: inline-block;
    font-size: 20px;
    position: absolute;
    right: 0;
    background: white;
}

.actions svg {
    margin: 0 10px;
    cursor: pointer;
    transition: .4s all;
}

.actions svg.visible path {
    fill: #65B331 !important;
}

.message {
    display: block;
    margin: 25px 0;
    text-align: center;
}

/************ PAGINATION ***************/
.pagination {
    display: block !important;
    padding-top: 24px !important;
}

.page-items {
    align-items: center;
    display: flex;
    width: auto;
    float: right;
}

.page-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: .4s all;
}

.page-item.active, .page-item:hover:not(.disabled) {
    background: #1068A0;
}

.page-item.active div,
.page-item:hover:not(.disabled) div {
    color: white;
}

.page-item div {
    color: #6C6C6C;
    text-align: center;
    margin: auto;
}