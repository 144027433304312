.user-box {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0px 8px 6px rgba(0,0,0,.16);
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
}

.user-box .img {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 75%;
    background-color: #707070;
    border-radius: 50%;
    margin: auto;
}

.user-box .info {
    text-align: center;
    margin: 20px 0;
}

.user-box .info p {
    font-size: 1em;
    line-height: 22px;
    letter-spacing: 0px;
    margin: 0 !important;
}

.user-box .info .name {
    font-weight: 600;
    color: #707070;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-box .info .rol {
    font: normal normal normal 17px/22px Segoe UI;
    color: #65B331;
}

.user-box .bottom {
    display: flow-root;
}

.user-box .bottom .delete-user {
    fill: #C1BABA;
    cursor: pointer;
}

.user-box .bottom .delete-user:hover {
    fill: #4969D4;
}

.user-box .bottom .details {
    float: right;
    color: #4969D4;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
