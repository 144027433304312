input[type="file"] {
    display: none;
}

.pull-right {
    display: 'flex';
    justify-content: 'flex-end';
    margin-bottom: 1rem;
}

/* Snow Theme */
.editor .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.editor .ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.editor .ql-editor {
    min-height: 10em;
}

.editor .ql-editor::placeholder {
    font-style: normal;
}

.editor .ql-container.ql-snow {
    min-height: 300px;
}

.periodo {
    display: flex;
    align-items: baseline;
}

.periodo label {
    margin-right: 10px
}

.periodo .select-value {
    color: #808080;
}

.title .greentext {
    color: #59C576;
    letter-spacing: 0px;
    font-size: 1.875rem;
    line-height: 2.5rem;
    width: auto;
    display: contents;
    font-weight: bold !important;
}

.video-input {
    display: flex;
    align-items: center;
}

.video-input svg {
    font-size: 3.125rem;
    margin-right: 10px;
    fill: #A5A5A5;
}

.filter {
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filter svg {
    font-size: 1.688rem;
    fill: black;
}

.filter svg path {
    fill: #4969D4;
}

.filter span {
    color: #4969D4;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-left: 10px;
}

.noticia {
    width: 100%;
    margin: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 6px #00000029;
    border: 1px solid #C1BABA;
    border-radius: 6px;
    transition: .4s all;
}

.noticia.over {
    opacity: .6;
}

.noticia .top, .noticia .center, .noticia .bottom {
    display: flow-root;
    align-items: center;
    padding: 10px;
}

.noticia .top svg {
    font-size: 2rem;
    fill: #8E8E8E;
}

.noticia .top .position {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    font-size: 1.438rem;
    line-height: 1.875rem;
    letter-spacing: 0px;
    color: #000000;
    float: right;
    width: 40px;
    height: 40px;
    text-align: center;
    display: grid;
    align-items: center;
    font-weight: bold;
}

.noticia .cover {
    width: 100%;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
}

.noticia .center svg {
    fill: #A5A5A5;
    float: right;
    cursor: pointer;
    transition: .4s all;
}

.noticia .center svg.visible {
    fill: #59C576;
}

.noticia .center svg.visible:hover {
    transform: scale(1.5);
}

.noticia .center span {
    letter-spacing: 0px;
    color: #000000;
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: bold;
}

.noticia p {
    margin: 0 !important;
    padding: 0 15px;
    font-size: 1.5rem;
    height: 70px;
    line-height: 2.1rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.noticia p {
    scrollbar-width: thin;
    scrollbar-color: #8e8e8e #ffffff;
    overflow: auto;
}

/* Chrome, Edge, and Safari */
.noticia p::-webkit-scrollbar {
    width: 10px;
}

.noticia p::-webkit-scrollbar-track {
    background: #ffffff;
}

.noticia p::-webkit-scrollbar-thumb {
    background-color: #8e8e8e;
    border-radius: 20px;
    border: 3px solid #ffffff;
}

.noticia .bottom svg {
    fill: #C1BABA;
    cursor: pointer;
}

.noticia .bottom svg:hover {
    fill: #4969D4
}

.noticia .bottom .more {
    float: right;
    display: flex;
    align-items: center;
    color: #4969D4;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
}

.noticia .bottom .more svg {
    fill: #4969D4;
}

.popUp-wrapper {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    margin-left: -293px;
    z-index: 250;
    background: rgb(0 0 0 / 10%);
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.popUp-wrapper.show {
    opacity: 1;
    visibility: visible;
}

.popUp-wrapper .popUp {
    display: block;
    height: 410px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    padding: 20px;
}

.popUp-wrapper .popUp .popUp-body {
    height: 100%;
    overflow: auto;
    position: relative;
}

.popUp-wrapper .popUp .head {
    display: flex;
    align-items: center;
    position: relative;
    border-color: #707070;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    padding-bottom: 15px;
}

.popUp-wrapper .popUp .head .title {
    letter-spacing: 0px;
    color: #333333;
    width: fit-content;
}

.popUp-wrapper .popUp .head svg {
    float: right;
    position: absolute;
    right: 0;
    fill: #707070;
    font-size: 2rem;
    cursor: pointer;
}

.popUp-wrapper .popUp .content .filter-box .title {
    color: #4969D4;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
    padding: 0 !important;
}

.popUp-wrapper .popUp .content .filter-box .filters {
    margin: 20px 0;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.popUp-wrapper .popUp .content .filter-box .filters .text {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
    margin-right: 45px;
}

.popUp-wrapper .popUp .content .filter-box .filters .MuiFormGroup-root {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}


.popUp-wrapper .popUp .content .filter-box .filters .MuiFormGroup-root .option {
    width: calc(100% / 3);
}

.popUp-wrapper .popUp .content .filter-box .filters .MuiFormGroup-root .option .MuiIconButton-colorSecondary:hover {
    background: transparent !important;
}

.popUp-wrapper .popUp .content .filter-box .filters .MuiFormGroup-root .option .MuiRadio-colorSecondary.Mui-checked {
    color: #59C576 !important;
}

.popUp-wrapper .popUp .content .filter-box .filters .MuiFormGroup-root .option .MuiCheckbox-colorSecondary.Mui-checked {
    color: #225BF2;
}

.popUp-wrapper .popUp .bottom {
    width: 100%;
    position: relative;
    bottom: 0;
}

.popUp-wrapper .popUp .bottom button {
    background-color: #4969D4 !important;
}