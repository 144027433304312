.title {
    width: fit-content;
    display: inline-flex;
    padding: 0px 22px !important;
}

@media (max-width: 767px) {
    .form-title .col-md-6 {
        text-align: center;
    }

    .form-title .active-switch {
        float: unset;
        padding: 22px 22px 0 22px;
    }

    .form-title .title {
        width: 100%;
    }
}

.form-input {
    padding: 0 10px;
}

.select-container {
    position: relative;
    box-sizing: border-box;
}

.select-container .select-control {
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
}

.select-container .select-control:hover {
    border-color: hsl(0, 0%, 70%);
}

.select-container .select-control .select-value {
    align-items: center;
    display: flex;
    flex: 1px;
    flex-wrap: wrap;
    padding: 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box,
}

.select-container .select-control .select-value .value {
    width: calc(100% - 50px);
}

.select-container .select-control .select-value .value-container {
    display: contents;
}

.select-container .select-control .select-value .value-container .value {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: flex;
    margin: 2px;
    min-width: 0px;
    box-sizing: border-box;
    width: fit-content !important;
}

.select-container .select-control .select-value .value-container .value .title {
    border-radius: 2px;
    color: rgb(51, 51, 51);
    font-size: 85%;
    overflow: hidden;
    padding: 3px 3px 3px 6px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.select-container .select-control .select-value .value-container .value .icon {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    transition: all 0.4s;
    cursor: pointer;
}

.select-container .select-control .select-value .value-container .value .icon.remove svg {
    width: 16px;
    height: 16px;
    transition: all 0.4s;
}

.select-container .select-control .select-value .value-container .value .icon.remove:hover {
    background: #ff00005c;
}

.select-container .select-control .select-value .value-container .value .icon.remove:hover svg {
    color: red;
}

.select-container .select-control .select-value .selected-item {
    display: inline-block;
    padding: 0 5px;
    background: #eee;
    border-radius: 3px;
    margin: 0px 3px;
    transition: .4s all;
    cursor: pointer;
}

.select-container .select-control .select-value .selected-item:hover {
    background: #d5d5d5;
}

.select-container .select-control .select-value .placeholder {
    color: hsl(0,0%,50%);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}

.select-container .select-control .arrow-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.select-container .select-control .arrow-container .arrow-separator {
    align-self: stretch;
    background-color: hsl(0,0%,80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.select-container .select-control .arrow-container .arrow-content {
    color: hsl(0,0%,80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
    cursor: pointer;
}

.select-container .select-control .arrow-container .arrow-content:hover svg {
    fill: hsl(0, 0%, 60%);
}

.select-container .select-options {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    max-height: 482px;
    overflow: auto;
    outline: 0 !important;
    position: absolute;
    transition: all 100ms;
    box-sizing: border-box;
    z-index: 1;
    margin-top: 5px;
    width: 100%;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%),
    0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    opacity: 0;
    visibility: hidden;
}

.select-container .select-options .select-option {
    display: block;
    width: 100%;
    padding: 8px;
    position: relative;
    transition: .4s all;
}

.select-container .select-options .select-option:hover {
    background-color: #DEEBFF;
}

.select-container .select-options .select-option.active {
    background-color: #2684FF;
    color: white;
}

.select-container .select-options .select-option input::placeholder {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: italic;
}

.select-container .select-options .select-option .actions {
    position: absolute;
    top: 2px;
    right: 0;
    background: unset;
}

.select-container .select-options .select-option .actions.hoverable {
    opacity: 0;
    visibility: hidden;
}

.select-container .select-options .select-option:hover .actions.hoverable {
    opacity: 1;
    visibility: visible;
}

.select-container .select-options .select-option .actions svg.Check {
    fill: #77ce7a;
}

.select-container .select-options .select-option .actions svg.Delete {
    fill: red;
}

.select-container .select-options .select-option input {
    border: none;
    outline: none;
    width: 100%;
    background: none;
}

.select-container.open .select-options{
    opacity: 1;
    visibility: visible;
}

.select-container.open .select-control {
    border-color: #80bdff;
    outline: 0px;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.select-container.open:hover .select-control .arrow-container .arrow-content svg {
    fill: hsl(0, 0%, 20%);
}

.horario {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, auto);
    grid-column-gap: 5px;
}

.horario span {
    font-weight: 700;
}

.last-modification {
    font-weight: 700;
}