.form-group .MuiCheckbox-colorSecondary.Mui-checked {
    color: #225BF2 !important;
}

.form-group .MuiRadio-colorSecondary.Mui-checked {
    color: #59C576 !important;
}

.form-group .MuiRadio-colorSecondary:hover,
.form-group .MuiCheckbox-colorSecondary:hover {
    background: transparent !important;
}

.form-group .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
}

.form-group .text {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.form-group .MuiFormControlLabel-root.right {
    float: right;
    margin-right: 30px;
}

.form-group .aranceles {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.form-group .aranceles.show {
    max-height: 550px;
    transition: max-height 0.25s ease-in;
}

.form-group .aranceles table,
.form-group .aranceles div {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-out;
}

.form-group .aranceles.show table,
.form-group .aranceles.show div {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease-in;
}

.form-group .aranceles table td,
.form-group .aranceles table th {
    border: none !important;
}

.form-group .aranceles table td {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
    text-align: center;
}

.form-group .aranceles table td:nth-child(2),
.form-group .aranceles table td:nth-child(3) {
    text-align: center;
}

.form-group .aranceles table th {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.313rem;
    vertical-align: baseline;
}

.form-group .aranceles table th:nth-child(1) {
    padding-left: 20px;
}
.form-group .aranceles table th:nth-child(2) {
    text-align: start;
    color: #65B331;
    font-size: 1rem;
    line-height: 1.313rem
}

.form-group .aranceles table th:nth-child(3),
.form-group .aranceles table th:nth-child(4) ,
.form-group .aranceles table th:nth-child(5) ,
.form-group .aranceles table th:nth-child(6) ,
.form-group .aranceles table th:nth-child(7)  {
    text-align: center;
    color: #65B331;
    font-size: 1rem;
    line-height: 1.313rem;
}

.form-group .largaEstadias {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.form-group .largaEstadias.show {
    max-height: 550px;
    transition: max-height 0.25s ease-in;
}

.form-group .largaEstadias table,
.form-group .largaEstadias div {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-out;
}

.form-group .largaEstadias.show table,
.form-group .largaEstadias.show div {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease-in;
}

.form-group .largaEstadias table td,
.form-group .largaEstadias table th {
    border: none !important;
}

.form-group .largaEstadias table td {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
    text-align: center;
}

.form-group .largaEstadias table th {
    font-weight: normal;
    font-size: 1.063rem;
    line-height: 1.438rem;
    vertical-align: baseline;
    text-align: center !important;
}

.form-group .largaEstadias table th .periodo {
    display: unset !important;
    font-size: 1rem;
    line-height: 1.313rem;
    color: #65B331;
}

.form-group .largaEstadias table th .dias {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
}

.form-group .largaEstadias table th:nth-child(2) input {
    width: calc(100% - 70px);
    display: inline-block;
}

.form-group .largaEstadias table th:nth-child(1),
.form-group .largaEstadias table th:nth-child(2) {
    text-align: center;
    color: #65B331;
    font-size: 1rem;
    line-height: 1.313rem;
}


 .form-group .cochera {
    margin: 15px auto;
    display: flex;
    align-items: center;
}

.form-group .cochera label {
    margin-bottom: 0 !important;
    margin-right: 16px;
    width: auto;
}

.form-group .cochera input {
    width: 100px;
}

.form-group .cochera .greenText {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.313rem;
    letter-spacing: 0px;
    color: #65B331;
    opacity: 1;
}

 .form-group .title-section {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.form-group .title-section svg {
    font-size: 2rem;
    margin-right: 10px;
}

.form-group .title-section h3 {
    margin: 0 !important;
    font-size: 1.3rem;
}

.form-group .title-section .greentext {
    color: #65B331;
    font-size: 1rem;
    line-height: 1.313rem;
    margin-left: 10px;
}

.form-group .title-section span:not(.greentext) {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    float: right;
    position: absolute;
    right: 20px;
}

.form-group .title-section span.enable {
    color: #65B331;
}

.form-group .title-section span.disabled {
    color: #A5A5A5;
}

.form-group label.label.medioPago {
    text-align: center;
}

.form-group .medioDePago {
    padding: 0 10px;
    margin: 20px 0;
    height: 55px;
    display: flex;
    align-items: center;
}

/* .form-group .medioDePago .chk-switch {
    width: 40px;
    margin: 0 0 0 25px;
} */

.form-group .medioDePago .image {
    width: 60px;
    height: 60px;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.form-group .medioDePago .image img {
    max-width: 140px;
    max-height: 55px;
}

.form-group .medioDePago span {
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: bold;
    display: block;
    margin: auto;
}

.form-group .ubicacion {
    color: #9D9D9D;
    margin: 15px 0 15px 40px;
}

.form-group .estacionamiento-grid th,
.form-group .estacionamiento-grid td {
    border: none !important;
}

.form-group .estacionamiento-grid th:last-child {
    width: 55px;
}

.form-group .estacionamiento-grid th:nth-child(3) {
    width: 20%;
}

.form-group .estacionamiento-grid th:nth-child(1) {
    width: 25%;
}

.form-group .estacionamiento-grid td {
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: bold;
}

.form-group .estacionamiento-grid th {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: normal;
    color: #9D9D9D !important;
}

.form-group .estacionamiento-grid th .icon-text {
    text-align: initial;
    font-size: 1rem;
    line-height: 1.188;
}

.form-group .estacionamiento-grid th ul {
    list-style-type: none;
    margin: 0
}

.form-group .estacionamiento-grid .input-check .valid-box {
    display: unset !important;
    text-align: center;
    width: 32px;
}

.form-group .estacionamiento-grid .input-check .valid-box .remove {
    fill: red;
    cursor: pointer;
}

.form-group .estacionamiento-grid .ToAdd {
    display: flex;
    align-items: center;
}

.form-group .estacionamiento-grid .ToAdd textarea {
    width: calc(100% - 45px);
    float: left;
}

.form-group .estacionamiento-grid .ToAdd .actions {
    position: unset;
    margin: auto;
}

.form-group .estacionamiento-grid .ToAdd .actions svg {
    font-size: 1.7rem;
    margin: auto;
    display: block;
}

.form-group .estacionamiento-grid .ToAdd .actions svg.save {
    fill: #29ab2d;
}

.form-group .estacionamiento-grid .remove {
    color: #860A5D;
    cursor: pointer;
}

.form-group .estacionamiento-grid .link {
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.313rem;
    color: #4969D4;
    float: right;
    display: flex;
}

.form-group .estacionamiento-grid .link svg {
    background: #4969D4;
    color: white;
    margin-right: 5px;
    border-radius: 3px;
}

.form-group .estacionamiento-grid .valid-box.small {
    height: 38px;
}

.form-group .estacionamiento-grid .valid-box.small svg.check {
    fill: white;
    border-radius: 0 0.25rem 0.25rem 0;
    background: #59C576;
    height: 36px;
}

.form-group .estacionamiento-grid .valid-box svg {
    cursor: pointer;
}

.form-group .estacionamiento-grid .valid-box svg:hover {
    transform: scale(1.1);
}

.form-group .estacionamiento-grid .valid-box.big svg.check {
    fill: white;
    border-radius: 0 0.25rem 0 0;
    background: #59C576;
}

.form-group .estacionamiento-grid .valid-box.big svg.wrong {
    fill: white;
    border-radius: 0 0 0.25rem 0;
    background: red;
}

.form-group .title-section .active-switch {
    position: absolute;
    right: 26px;
}


.addVehicleButton, 
.addLargaEstadiaButton {
    display: flex;
    padding-left: 25px;
}
.addVehicleSpan, 
.addLargaEstadiaSpan {
    margin-left: 10px;
    color: blue;
}

.arancelNewColumn {
    display: flex;
    align-items: center;
    flex-direction: column;
}
/* .form-group .chk-switch {
    display: flex;
    align-items: center;
    margin: 0 20px 0 auto;
}

.form-group .chk-switch label {
    margin: 0 30px 0px 0px !important;
}

.form-group .chk-switch input[type=checkbox] {
    cursor: pointer;
    transform: translateY(50%);
}

.form-group .chk-switch input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    top: -14px;
    left: -23px;
    height: 28px;
    width: 55px;
    background-color: #C1BABA;
    border-radius: 14px;
    transition: background-color 0.2s;
    cursor: pointer;
}

.form-group .chk-switch input[type=checkbox]:after {
    content: "";
    display: block;
    position: absolute;
    transition: transform 0.2s;
    top: -12px;
    left: 5px;
    border-radius: 12px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.form-group .chk-switch input[type=checkbox]:checked:before {
    background-color: #77CE7A;
}

.form-group .chk-switch input[type=checkbox]:checked:after {
    transform: translateX(-25px);
    -webkit-transform: translateX(-25px);
} */
