.cierreAeropuerto-box {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, .16);
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
}

.cierreAeropuerto-box .top svg {
    float: right;
    cursor: pointer;
    transition: .4s all;
}

.cierreAeropuerto-box .top svg.activo {
    fill: #59C576;
}

.cierreAeropuerto-box .top svg.activo:hover {
    fill: #A5A5A5;
}

.cierreAeropuerto-box .top svg.inactivo {
    fill: #A5A5A5;
}

.cierreAeropuerto-box .cierreAeropuerto-name {
    display: grid;
    align-items: center;
    font-size: 1em;
    line-height: 1rem;
    color: #860A5D;
    text-align: center;
    margin-bottom: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.cierreAeropuerto-box .cierreAeropuerto-logo {
    background: #E9E9E9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 25px;
    margin: auto;
    margin-bottom: 15px;
}

.cierreAeropuerto-box .cierreAeropuerto-logo svg {
    font-size: 50px;
}

.cierreAeropuerto-box .cierreAeropuerto-logo svg path {
    fill: #1068A0;
}

.cierreAeropuerto-box .bottom {
    display: flow-root;
}

.cierreAeropuerto-box .bottom .del {
    fill: #C1BABA;
    cursor: pointer;
}

.cierreAeropuerto-box .bottom .del:hover {
    fill: #4969D4;
}

.cierreAeropuerto-box .bottom .details {
    float: right;
    color: #4969D4;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}