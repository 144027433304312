.label i.required {
    color: red;
}

.buttonImg {
    width: fit-content;
}

.validImages {
    list-style-type: circle;
    color: red;
}

.help {
    display: inline !important;
}

.help.maxlength {
    float: right;
    margin-bottom: 0;
    color: #6c757d;
}

/*SWTICH */

.active-switch {
    display: inline-flex;
    align-items: center;
    float: right;
    transform: translateY(25%);
}

.active-switch .label {
    margin: 0 25px 0px 0px !important;
}

.active-switch input[type=checkbox] {
    display: none;
}

.active-switch label.switch {
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: #c1baba;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0;
}

.active-switch label.switch::after {
    content: '';
    position: absolute;
    width: 19px;
    height: 19px;
    top: 3px;
    left: 3px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.5s;
    transform: translateX(0px);
}

.active-switch input[type=checkbox]:checked+label.switch {
    background: #77ce7a;
}

.active-switch input[type=checkbox]:checked+label.switch::after {
    transition: all 0.5s;
    transform: translateX(25px);
}