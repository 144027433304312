.control.password svg {
    position: absolute;
    right: 7px;
    top: 7px;
    color: #1D6899;
    background-color: white;
    cursor: pointer;
}

input::-webkit-input-placeholder {
    direction: rtl;
    font-family: FontAwesome;
}

.padding-top-threePercent {
    padding-top: 3%;
}
 
.logo {
    text-align: center;
    padding-top: 60px;
}

.logo img{
    width: auto;
    height: 30px;
}

.titleLogin{
    text-align: center;
    padding-top: 50px;
    color:#1D6899
}

@media screen and (max-width: 768px) {

    .logo img {
        width: auto;
        height: 30px;
    }
}

@media screen and (max-width: 576px) {

    .login {
        width: 100%;
    }
    .logo img {
        width: auto;
        height: 30px;
    }
}