.content #home-title {
    font-size: 1.875rem;
    line-height: 2.438rem;
    text-align: center;
}

.content #home-title strong {
    display: block;
}

.content .boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    width: 100%;
}

.content .boxes .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    padding: 15px;
    position: relative;
}

.content .boxes .box .name {
    display: grid;
    align-items: center;
    font-size: 1.125em;
    line-height: 1.5rem;
    color: #860A5D;
    text-align: center;
    margin-bottom: 0 !important;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-transform: uppercase;
}

.content .boxes .box .logo {
    display: flex;
    margin: auto;
    width: 100px;
    height: 100px;
    background: #E9E9E9;
    border-radius: 50%;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0;
}

.content .boxes .box .logo svg {
    margin: auto;
    width: 55px;
    height: 55px;
}

.content .boxes .box .logo svg path {
    fill: #1068A0;
}

.content .boxes .box .bottom {
    width: 100%;
    margin-top: auto;
}

.content .boxes .box .bottom .details {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    float: right;
    color: #4969D4;
}

.content .boxes .box .bottom .details.left {
    float: unset !important;
    margin-right: 5px;
}

.content .boxes .box .bottom .details svg {
    margin-left: -4px;
    margin-bottom: -4px;
}

@media only screen and (max-width: 1670px) {
    .boxes {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media only screen and (max-width: 1400px) {
    .boxes {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}


@media only screen and (max-width: 1000px) {
    .boxes {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}