.groupName {
  color: #860A5D;
}

.btnDelete {
  background: transparent !important;
  box-shadow: none !important;
  color: #707070 !important;
  text-transform: inherit !important;
  outline: none !important;
}

.btnDelete:hover {
  background: transparent;
  box-shadow: none;
  color: #4969D4 !important;
}

.box {
  height: 100%;
}

.form-group.g-info {
  height: 700;
}

.form button.user-add {
  margin: 35px auto 10px auto;
}

.form .input-box {
  display: flex;
  align-items: center;
}

.form .input-box .width {
  width: -webkit-fill-available;
}

.form .input-box svg {
  font-size: 1.875em;
  fill: #59C576;
  cursor: pointer;
}

.form .input-box svg.margin-bottom {
  margin-bottom: 35px;
}

.form .users {
  margin-top: 10px;
  max-height: 400px;
  min-height: 135px;
  overflow-y: auto;
  -ms-overflow-style: none;/* IE and Edge */
  scrollbar-width: none;/* Firefox */
}

.form .users::-webkit-scrollbar {
  display: none;
}

.form .users .user {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 5px 0;
}

.form .users .user svg {
  font-size: 1.7em;
  line-height: 33px;
}

.form .users .user .icon {
  fill: #860A5D;
}

.form .users .user .delete {
  fill: #A5A5A5;
  background: transparent;
}

.form .users .user div {
  color: #9D9D9D;
  width: inherit;
  padding: 0 5px;
  font-size: 1.5em;
  line-height: 33px;
}

.form .user-search {
  position: relative;
}

.form .user-search .search {
  display: flex;
  align-items: center;
}

.form .user-search .user-list {
  position: absolute;
  z-index: 1;
  background: white;
  width: 100%;
  border: 1px solid #ced4da;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  transition: .4s all;
}

.form .user-search .user-list.show {
  opacity: 1;
  visibility: visible;
}

.form .user-search .user-list .user {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.form .user-search .user-list .user:hover {
  background: #eee;
}

.form .user-search .user-list .user svg {
  margin-right: 10px;
}

.nodos {
  margin-top: 25px;
  display: flow-root;
  overflow-y: scroll;
  height: 610px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nodos::-webkit-scrollbar {
  display: none;
}

.nodos .MuiCollapse-wrapperInner {
  width: 98%;
}

.nodos .nodo .nodo-title {
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  background: #e9e9e9;
  margin: 10px 0;
}

.nodos .nodo .nodo-title .form-label {
  font-size: 1em;
  line-height: 23px;
  color: #333333;
  margin-left: 10px;
  width: -webkit-fill-available;
}

.nodos .nodo .nodo-title .icon {
  display: contents;
  cursor: pointer;
}

.nodos .nodo .nodo-title .icon svg {
  font-size: 35px;
}

.nodo .form-group {
  margin: 0 !important;
}

.nodo .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.nodo .form-group label {
  position: relative;
  cursor: pointer;
}

.nodo .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #59c576;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
  inset 0 -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 4px;
  height: 35px;
  width: 35px;
  background: #fff;
}

.nodo .form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 13px;
  width: 10px;
  height: 20px;
  border: solid #59c576;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.nodo .form-group input.indeterminate+label::after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 5px;
  width: 25px;
  border: solid #59c576;
  border-width: 0 0 5px;
  border-radius: 5px;
}

.nodos .nodo .nodo-content {
  display: grid;
  margin-left: 35px;
}

.nodos .nodo .active-switch {
  display: inline-flex;
  align-items: center;
  float: left;
  margin: 15px;
}

.nodos .nodo .active-switch .label {
  margin: 0 0px 0px 25px !important;
}

.nodos .nodo .active-switch input[type=checkbox] {
  display: none;
}

.nodos .nodo .active-switch label.switch {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: #c1baba;
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0;
}

.nodos .nodo .active-switch label.switch::after {
  content: '';
  position: absolute;
  width: 19px;
  height: 19px;
  top: 3px;
  left: 3px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.5s;
  transform: translateX(0px);
}

.nodos .nodo .active-switch input[type=checkbox]:checked+label.switch {
  background: #77ce7a;
}

.nodos .nodo .active-switch input[type=checkbox]:checked+label.switch::after {
  transition: all 0.5s;
  transform: translateX(25px);
  /* background-color: #000; */
}

.scrollable {
    overflow: auto;
    height: 300px;
  }