.form-input .tarifas {
    color: #65B331;
    display: inline-flex;
    align-items: center;
}

.form-input .tarifas .span {
    font-size: 1.25rem;
    line-height: 1.688rem;
}

.form-input .tarifas input {
    margin: 0 10px;
}

.form-input .tarifas span.text {
    margin: 0 10px;
}

.form-input .SUBE {
    color: #65B331;
}

.form-input .text {
    color: #9D9D9D;
    font-size: 1.125rem;
    line-height: 1.5rem;
    opacity: .94;
    margin: 10px 0 0 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}