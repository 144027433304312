body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body{
  height:100%;
  width:100%;
  background-color: #f2f2f2 !important;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-center {
  text-align: center;
  padding-top: 25px;
}

.main-content {
  margin-left: 293px;
  margin-right: 40px;
  margin-bottom: 1%;
}

.content {
  padding-right: 2%;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 6px;

}

.title {
  padding: 7px 22px;
  font-weight: 59px;
  font-size: 1.6em;
  margin: -1px !important;
  color: #0a0a0a;
}

.loading-app:before {
  content: '';
  background: url('assets/img/Loading.gif') 50% 50% no-repeat  #d3c8c8a9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-size: 250px;
}

.loading-app:after {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;  
}

