.title {
  display: block !important;
}

.title .userName {
  color: #59C576;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
}

.btnAdd.gray {
  background-color: #A5A5A5 !important;
  cursor: inherit;
}

.btnAdd.gray:hover {
  background-color: #A5A5A5 !important;
}

.form {
  width: 100%;
}

.form label {
  margin: 0 !important;
}

.form span {
  display: block;
  margin-bottom: 20px;
  font-size: 1.2em;
  line-height: 33px;
  color: #9D9D9D;
}

.form .picture {
  position: relative;
  width: fit-content;
}

.form .picture .img {
  width: 153px;
  height: 153px;
  border: 1px solid #707070;
  background-size: contain;
  background-repeat: no-repeat;
}

.form .picture .btns {
  position: absolute;
  top: 5px;
  right: -25px;
}

.form .picture .btns .btn {
  background: #C1BABA 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 10px 12px;
  transition: .4s all;
}

.form .picture .btns .btn:hover {
  transform: scale(1.2);
}

.form .picture .btns .btn.remove {
  background: #da0f0f 0% 0% no-repeat padding-box;
}

.form .picture .btns .btn svg {
  fill: white;
}

.form .form-control,
.form .css-2b097c-container {
  margin: 10px 0;
}

.form .user-search {
  display: flex;
  align-items: center;
}

.form .user-search div {
  width: 100%;
}

.form .user-search div input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.form .user-search button {
  border-radius: 0 4px 4px 0;
  height: 38px;
}

.form-check {
  padding-left: 0 !important;
}

.form-check .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #000000;
}

.form-check p:nth-child(2) {
  letter-spacing: 0px;
  color: #707070;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  margin-left: 30px;
  margin-bottom: 5px;
}

.form-check p:nth-child(3) {
  letter-spacing: 0px;
  color: #707070;
  font-size: 12px;
  line-height: 16px;
  margin-left: 30px;
}

.form-check .MuiIconButton-colorSecondary:hover {
  background: transparent !important;
}

.form-check .MuiRadio-colorSecondary.Mui-checked {
  color: #59C576 !important;
}

.user-rol {
  margin-left: 25px;
  margin-top: 15px;
}

.user-rol span {
  letter-spacing: 0px;
  color: #333333;
  font-size: 25px;
  line-height: 38px;
  display: block;
}

.user-rol p:nth-child(2) {
  margin: 10px 0px 5px 0 !important;
}

.user-rol p:nth-child(3) {
  font-size: 12px;
  line-height: 16px;
  color: #707070;
}

.aeropuertos .aeropuerto {
  width: calc(100% / 5);
  display: inline-block;
  align-items: center;
  margin: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #A5A5A5;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 21px;
  text-align: end;
  transition: .4s all;
}

.aeropuertos .aeropuerto.cursor {
  cursor: pointer;
}

.aeropuertos .aeropuerto.TODOS {
  display: block;
  width: max-content !important;
}

.aeropuertos .aeropuerto span {
  width: fit-content;
  min-width: 31px;
  display: flex;
  margin: 0 0 0 auto;
}

.aeropuertos .aeropuerto svg {
  width: 16px;
  fill: #59C576;
  margin-right: 0;
  float: left;
  opacity: 0;
  visibility: hidden;
}

.aeropuertos .aeropuerto.active {
  font-weight: 700;
  border-color: #59C576;
}

.aeropuertos .aeropuerto.active svg {
  opacity: 1;
  visibility: visible;
}

.group {
  font-size: 25px;
  line-height: 33px;
  letter-spacing: 0px;
  color: #9D9D9D;
}

.react-select-wrapper.multi .Select-menu .Select-option:first-child {
  font-weight: 600;
}

@media (max-width: 1100px) {
  .aeropuerto {
    padding: 5px !important;
    width: calc(100% / 2.5) !important
  }
}