.group-box {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, .16);
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
}

.group-box .group-name {
    display: grid;
    align-items: center;
    font-size: 1.125em;
    line-height: 24px;
    color: #860A5D;
    text-align: center;
    margin-bottom: 10px !important;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.group-box .group-logo {
    display: flex;
    margin: auto;
    text-align: center;
    width: 65px;
    height: 65px;
    background: #E9E9E9;
    border-radius: 50%;
    position: relative;
    align-items: center;
}

.group-box .group-logo svg {
    font-size: 2.1875em;
    margin: auto;
}

.group-box .group-logo svg path {
    fill: #1068A0;
}

.group-box .group-logo:hover .members-info {
    opacity: 1;
    visibility: visible;
}

.group-box .group-info .members {
    display: flex;
    position: absolute;
    padding: 5px 10px;
    right: -10px;
    bottom: -5px;
    border-radius: 50%;
    background: #59C576;
    color: white;
    font-size: 1em;
    line-height: 18px;
    cursor: pointer;
}

.group-box .group-info .members-info {
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    list-style-type: none;
    padding: 5px 10px;
    position: absolute;
    left: 35px;
    top: 35px;
    opacity: 0;
    visibility: hidden;
    transition: .4s all;
    z-index: 2;
    width: max-content;
}

.group-box .group-info .members-info li {
    display: flex;
    align-items: center;
}

.group-box .group-info .members-info li:last-child span {
    color: #4969D4;
    text-align: end;
    display: block;
    width: 100%;
    font-size: 0.8125em;
    line-height: 18px;
    cursor: pointer;
    margin-top: 5px;
}

.group-box .group-info .members-info li svg {
    font-size: 1em !important;
    margin-right: 5px !important;
    margin-left: 0;
}

.group-box .group-info .members-info li svg path {
    fill: #A5A5A5 !important;
}

.group-box .group-info .members-info li span {
    font-size: 14px;
    line-height: 19px;
    color: #060606;
}

.group-box .group-description {
    display: grid;
    align-items: center;
    font-size: 0.875em;
    line-height: 19px;
    color: #9D9D9D;
    text-align: center;
    margin: 15px 0;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-box .bottom {
    display: flow-root;
}

.group-box .bottom .delete-user {
    fill: #C1BABA;
    cursor: pointer;
}

.group-box .bottom .delete-user:hover {
    fill: #4969D4;
}

.group-box .bottom .details {
    float: right;
    color: #4969D4;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}